import React from "react";
import styles from "./Accordion.module.scss";

const PaperAccordion = () => {
  return (
    <div className={styles.accordion}>
      {/* Fotopapir Sizes and Prices */}
      <div className={styles.shape_wrap}>
        <h4 className="mb-1">Professional Photo-paper</h4>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>20 x 20cm</p>
          <p className={styles.price}>499 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>30 x 30cm</p>
          <p className={styles.price}>799 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>40 x 40cm</p>
          <p className={styles.price}>999 NOK</p>
        </div>
      </div>

      <div className={styles.shape_wrap}>
        <h4 className="mb-1">Iris Prices</h4>
        <p className="mb-1">
          Price for iris amount is added to the price of the image size.
        </p>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>1 iris</p>
          <p className={styles.price}>0 additional cost</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>Each additional iris</p>
          <p className={styles.price}>+499 NOK</p>
        </div>
      </div>

      <div className={styles.shape_wrap}>
        <h4 className="mb-1">Frames</h4>
        <p className="mb-1">Studio Frames</p>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>20 x 20cm</p>
          <p className={styles.price}>189 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>30 x 30cm</p>
          <p className={styles.price}>269 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>40 x 40cm</p>
          <p className={styles.price}>349 NOK</p>
        </div>
      </div>
    </div>
  );
};

export default PaperAccordion;
