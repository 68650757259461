import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";
import logo2 from "../../assets/logodark.png";
import ig from "../../assets/ig-icon.svg";
import fb from "../../assets/fb-icon.svg";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="wrap">
          <div className={styles.content_flex}>
            <div className={styles.footer_card}>
              <h4>Kontakt Oss</h4>
              <p>post@instantiris.no</p>
              <p>Steinkjellergaten 2, 5003 Bergen</p>
            </div>
            <div className={styles.footer_card}>
              <h4>Åpningstider</h4>
              <strong>Avtaler må bookes</strong>
              <p>Tirsdag: 13:00 - 19:00</p>
              <p>Torsdag: 13:00 - 19:00</p>
              <p>Lørdag: 12:00 - 18:00</p>
            </div>
            <div className={styles.footer_card}>
              <div className={styles.icon_wrap}>
                <a href="https://www.instagram.com/instant.iris/">
                  <img className={styles.icon} src={ig} alt="instagram-icon" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61551954833049">
                  <img className={styles.icon} src={fb} alt="facebook-icon" />
                </a>
              </div>
            </div>
            <div className={styles.footer_card}>
              <div className={styles.logo}>
                <Link to="/">
                  <img src={logo2} alt="Logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
